import Disclaimer from "../../components/disclaimer";
import GridItem from "../../components/grid-item";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import useDesktopCheck from "../../hooks/useDesktopCheck";
import Layout from "../../Layout";
import { Helmet } from "react-helmet";

const title = "Caraiman – gust echilibrat, numai bun să-ți tragi sufletul ";
const description =
  "Descoperă rețeta naturală a berii Caraiman cu gust echilibrat, inspirat de liniștea muntelui, ca să-ți tragi sufletul oricând simți nevoia de o pauză.";

const PromotionRules = () => {
  const height = useWindowResizeEffect();
  const isDesktop = useDesktopCheck();
  const promotionItems = [
    {
      title: "CAMPANIE PET 2,5L CU DOZĂ 0,5L CADOU",
      description: "1 ian - 28 feb 2025",
      action: "/pdfs/RegulamentNCPCaraiman-1ian2025.pdf",
    },
    {
      title: "PROMOȚIA CU BERI SUB CAPAC ȘI BANI SĂ-ȚI FACI O CABANĂ",
      description: "1 aug 2024 - 28 feb 2025",
      action: "/pdfs/RegulamentNCPCaraiman-1aug2024.pdf",
    },
  ];

  const handleGridItemClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Layout>
      <Helmet>
        <title>Regulamentul promotiei | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div
        style={{
          minHeight: height + 20,
          backgroundImage: isDesktop
            ? "url('/images/regulament-promotie/cabana_desktop.png')"
            : "url('/images/regulament-promotie/cabana_mobile.png')",
        }}
        className="flex flex-col lg:flex-row items-center lg:items-start flex-grow bg-secondary-grey pt-16 lg:pt-20 w-full bg-no-repeat bg-right-bottom"
      >
        <div className="flex flex-col items-center lg:items-start lg:min-w-max px-8 lg:p-10">
          <h1 className="font-extrabold text-center lg:text-left text-3xl mt-8 text-primary-green">
            {isDesktop ? (
              <>
                PE SCURT. TOTUL ÎNCEPE CU
                <br />
                TRAS SUFLETUL LA UN
                <br />
                CARAIMAN RECE. DAR CA SĂ ȘTII
                <br />
                PRECIS CUM POȚI SĂ CÂȘTIGI.
                <br />
                ȚI-AM PUS TOATE DETALIILE AICI.
              </>
            ) : (
              "PE SCURT. TOTUL ÎNCEPE CU TRAS SUFLETUL LA UN CARAIMAN RECE. DAR CA SĂ ȘTII PRECIS CUM POȚI SĂ CÂȘTIGI. ȚI-AM PUS TOATE DETALIILE AICI."
            )}
          </h1>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-5 lg:p-10 mb-5 lg:mb-0">
          {/* Generate grid items */}
          {promotionItems.map((item, index) => (
            <GridItem
              key={index}
              title={item.title}
              description={item.description}
              onClick={() => handleGridItemClick(item.action)}
            />
          ))}
        </div>
        <Disclaimer
          image="/images/icons-green.png"
          textColor="text-primary-green"
        />
      </div>
    </Layout>
  );
};

export default PromotionRules;
