import Layout from "../../Layout";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./style.css";
import useDesktopCheck from "../../hooks/useDesktopCheck";
import { useSubmitPartnerData } from "../../mutations/promotion-form";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const validationSchema = Yup.object({
  code: Yup.string().required("Codul este obligatoriu."),
  firstName: Yup.string().required("Prenumele este obligatoriu."),
  lastName: Yup.string().required("Numele este obligatoriu."),
  email: Yup.string()
    .email("Email invalid.")
    .required("Emailul este obligatoriu."),
  phone: Yup.string()
    .matches(/^(\+40|0)[0-9]{9}$/, "Numarul de telefon nu este valid.")
    .required("Telefonul este obligatoriu."),
  county: Yup.string().required("Judetul este obligatoriu."),
  terms: Yup.boolean()
    .required("Regulamentul este obligatoriu.")
    .oneOf([true], "Consimțământul pentru Regulament este obligatoriu."),
  dataProcessing: Yup.boolean()
    .required("Consimțământul pentru prelucrarea datelor este obligatoriu.")
    .oneOf(
      [true],
      "Consimțământul pentru prelucrarea datelor este obligatoriu."
    ),
});

const title = "Caraiman – gust echilibrat, numai bun să-ți tragi sufletul ";
const description =
  "Descoperă rețeta naturală a berii Caraiman cu gust echilibrat, inspirat de liniștea muntelui, ca să-ți tragi sufletul oricând simți nevoia de o pauză.";

const Promotion = () => {
  const { mutate, data: response } = useSubmitPartnerData();
  const isOnDesktop = useDesktopCheck();
  const navigate = useNavigate();
  const counties = [
    "Alba",
    "Arad",
    "Argeș",
    "Bacău",
    "Bihor",
    "Bistrița-Năsăud",
    "Botoșani",
    "Brașov",
    "Brăila",
    "Buzău",
    "București",
    "Caraș-Severin",
    "Călărași",
    "Cluj",
    "Constanța",
    "Covasna",
    "Dâmbovița",
    "Dolj",
    "Galați",
    "Giurgiu",
    "Gorj",
    "Harghita",
    "Hunedoara",
    "Ialomița",
    "Iași",
    "Ilfov",
    "Maramureș",
    "Mehedinți",
    "Mureș",
    "Neamț",
    "Olt",
    "Prahova",
    "Satu Mare",
    "Sălaj",
    "Sibiu",
    "Suceava",
    "Teleorman",
    "Timiș",
    "Tulcea",
    "Vaslui",
    "Vâlcea",
    "Vrancea",
  ];

  const formik = useFormik({
    initialValues: {
      code: "",
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      county: "",
      terms: false,
      dataProcessing: false,
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const data = {
        cod: values.code,
        nume: values.lastName,
        prenume: values.firstName,
        email: values.email,
        telefon: values.phone,
        judet: values.county,
      };

      mutate(data);
      if (formik.isValid) {
        formik.resetForm();
        formik.setFieldValue("terms", false);
        formik.setFieldValue("dataProcessing", false);
      }
    },
  });

  useEffect(() => {
    if (response && response?.status === 1) {
      //@ts-ignore
      gtag("event", "success_code");
      navigate("/succes");
    } else if (response && response?.status !== 1) {
      //@ts-ignore
      gtag("event", "failed_code");
    }
  }, [response]);

  return (
    <Layout>
      <Helmet>
        <title>Promotia | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div className="flex flex-col items-center bg-secondary-grey">
        <div
          className="flex items-center justify-center relative w-full bg-cover bg-bottom lg:bg-center bg-no-repeat z-1 py-20"
          style={{
            backgroundImage: isOnDesktop
              ? "url('/images/promotia/promotia_bg.png')"
              : "url('/images/promotia/promotia_bg_mobile.png')",
            height: 800,
          }}
        />

        {/* Additional promotional content */}
        <div className="flex flex-col max-w-7xl w-full mt-4 lg:mt-8">
          <div className="relative -mb-14 lg:-mb-40 px-10">
            <div className="absolute mr-16 custom-md:mr-0 left-[75px] max-[426px]:left-[20%] custom-sm:left-20 lg:left-0 custom-lg:left-20 top-6 lg:top-4 custom-lg:top-10 lg:scale-75 custom-lg:scale-100">
              <p className="font-sans text-primary-green max-[426px]:text-xl text-2xl lg:text-3xl text-center lg:text-left">
                {isOnDesktop ? (
                  <>
                    Perioada de înscriere a codurilor s-a încheiat. <br />
                    Câștigătorul marelui premiu de <strong>100.000 €*</strong>
                    este
                  </>
                ) : (
                  <>
                    Perioada de înscriere a <br />
                    codurilor s-a încheiat. <br />
                    Câștigătorul marelui premiu <br />
                    de <strong>100.000 €*</strong>
                    este
                  </>
                )}
              </p>
              <p className="font-bold text-primary-green text-3xl lg:text-6xl uppercase text-center lg:text-left">
                {isOnDesktop ? (
                  "DUMITRU DAN NICOLAU"
                ) : (
                  <>
                    DUMITRU DAN
                    <br />
                    NICOLAU
                  </>
                )}
              </p>
              <p className="font-sans text-primary-green text-sm">
                {isOnDesktop ? (
                  <>
                    *acordat în lei, respectiv <strong>497 610 lei NET.</strong>
                  </>
                ) : (
                  <>
                    *acordat în lei, respectiv <br />{" "}
                    <strong>497 610 lei NET.</strong>
                  </>
                )}
              </p>
            </div>
            <img
              className="max-[378px]:h-[480px] h-[450px] lg:h-auto"
              src={
                isOnDesktop
                  ? "/images/promotia/valtoare_desktop_fara_text.png"
                  : "/images/promotia/valtoare_mobile_fara_text.png"
              }
              alt="valtoare"
            />
          </div>
          <div className="flex flex-col items-start md:w-1/2 p-10">
            <h2 className="font-bold text-primary-green text-3xl lg:text-5xl uppercase">
              {isOnDesktop ? (
                "AI GĂSIT O BERE?"
              ) : (
                <>
                  AI GĂSIT
                  <br />O BERE?
                </>
              )}{" "}
            </h2>
            <p className="font-sans text-left text-primary-green">
              Ia capacul sau cheița câștigătoare și fă un popas la cel mai
              <br />
              apropiat centru de premiere, în perioada <br />
              <strong>1.08.2024 - 28.02.2025.</strong>
            </p>
            <div className="flex flex-col lg:flex-row text-center mt-4 lg:space-x-2 space-y-2 lg:space-y-0">
              <button
                onClick={() => navigate("/promotia/centre")}
                className="bg-primary-green text-secondary-grey font-sans font-semibold text-2xl rounded px-5 py-3 text-nowrap"
              >
                VEZI LISTA CENTRE
              </button>
              <button
                onClick={() => navigate("/promotia/centre-de-premiere")}
                className="bg-primary-green text-secondary-grey font-sans font-semibold text-2xl rounded px-5 py-3 text-nowrap"
              >
                VEZI HARTA CENTRE
              </button>
            </div>
          </div>
        </div>

        {/* Product display */}
        <div className="flex flex-col max-w-7xl w-full -mt-10 pb-10 relative">
          <div className="lg:absolute flex flex-col top-48 custom-lg:top-56 px-10 mt-6">
            <h2 className="font-bold text-primary-green text-3xl lg:text-5xl uppercase">
              Produse participante
            </h2>
            <p className="font-sans text-left text-primary-green">
              Trage-ți sufletul cu orice Caraiman
              <br /> la sticlă, doză sau PET.
            </p>
          </div>
          {isOnDesktop ? (
            <div className="w-full max-w-[1800px] custom-md:-right-64 custom-lg:-right-80 custom-md:-top-[70%] custom-lg:-top-[77%]">
              <img src="/images/promotia/packshot.png" alt="packshot" />
            </div>
          ) : (
            <div className="w-full px-4">
              <img src="/images/promotia/packshot_mobile.png" alt="packshot" />
            </div>
          )}
        </div>
        <div className="absolute bottom-0 left-0 right-0 flex flex-row items-center justify-center p-2 md:justify-start z-20">
          <p className="text-primary-green text-xs md:text-xl font-sans font-semibold z-20">
            Consumă Caraiman în mod responsabil.
          </p>
          <img
            className="ml-2 w-16 lg:w-24 z-20"
            src="/images/icons-green.png"
            alt="icons"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Promotion;
